<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import ImgMedia from './ImgMedia.vue';

const props = defineProps({
    media: Array,
    classes: String,
});

const rightMedia = computed(() => {
    if (!Array.isArray(props.media)) {
        return null;
    }

    if (props.media.length == 0) {
        return null;
    }
    if (props.media.length == 1) {
        return props.media[0];
    }

    let locale = usePage().props.locale;

    let media = props.media.filter((media) => {
        return media.collection_name.endsWith(locale);
    });

    if (media.length == 0) {
        return null;
    }

    return media[0];
});
</script>

<template>
    <ImgMedia v-if="rightMedia" :media="rightMedia" :classes="classes" />
</template>
